import { Box, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';

import { Product } from '@/models/api';
import { AppService } from '@/services';

type CurrentPriceProps = {
    product: Product;
    currQuantity: number;
    currency?: string;
};

function CurrentPrice({ product, currQuantity }: CurrentPriceProps) {
    const currency = AppService.getInstanceCurrency();
    return (
        <Box
            textAlign="center"
            pos="absolute"
            w="100%"
            h="100%"
            bgColor="white"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            zIndex={currQuantity > 0 ? 10 : -1}
            color="blue.main"
            transition="opacity 0.2s ease-in-out"
            opacity={currQuantity > 0 ? 1 : 0}
            pointerEvents={currQuantity > 0 ? 'auto' : 'none'}
            willChange="opacity"
            px={10}
        >
            <Text fontSize={{ base: 24, md: 27 }} fontWeight="bold">
                <FormattedPrice value={product.priceGross} currency={currency} />
            </Text>
            <Text fontSize="sm" lineHeight="short">
                <FormattedMessage id="product.card.basket-updated" />
            </Text>
        </Box>
    );
}

export default CurrentPrice;
