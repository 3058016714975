import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';

import { AppService } from '@/services';

type ProductLowestPriceProps = {
    isNew?: boolean;
    discount?: number;
    lowestPriceIn30Days?: number;
    currency?: string;
    boxStyleProps?: BoxProps;
    textStyleProps?: TextProps;
};

export const ProductLowestPrice = (props: ProductLowestPriceProps) => {
    const { discount, lowestPriceIn30Days, isNew, boxStyleProps, textStyleProps } = props;
    const currency = AppService.getInstanceCurrency();
    const [lowestPriceVisible, setLowestPriceVisible] = useState(false);

    useEffect(() => {
        const isGermanInstance = AppService.isGermanInstance();

        if (isGermanInstance) {
            setLowestPriceVisible(!isNew && !!discount && !!lowestPriceIn30Days);
            return;
        }
        setLowestPriceVisible(!!discount && !!lowestPriceIn30Days);
    }, [discount, isNew, lowestPriceIn30Days]);

    return (
        <>
            {lowestPriceVisible && (
                <Box pt={1} {...boxStyleProps}>
                    <Text fontSize="sm" color="grey.700" {...textStyleProps}>
                        <FormattedMessage id="last-30-days-price" />:{' '}
                        <FormattedPrice value={lowestPriceIn30Days} currency={currency} />
                    </Text>
                </Box>
            )}
        </>
    );
};
