import { ButtonProps, Flex, FlexProps, Text, Tooltip, useToken } from '@chakra-ui/react';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useFavouriteButton } from '@/components/FavouriteButton/useFavouriteButton';
import { HeartIcon } from '@/components/Icons';
import { Button } from '@/components/UI/Buttons/Button';

import { CookieKeys } from '@/constants/cookie-keys';
import RoutePath from '@/constants/route-path';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';

import { useApp } from '../../../context/appContext';

type FavouriteButtonProps = {
    checked?: boolean;
    productCode: ProductIdProps;
    labelChecked?: string;
    labelUnchecked?: string;
    showLabel?: boolean;
    buttonStyleProps?: ButtonProps;
    containerStyleProps?: FlexProps;
    removeFromFavourite?: (code: ProductIdProps) => void;
};

export const FavouriteButton = ({
    checked,
    productCode,
    labelChecked,
    labelUnchecked,
    buttonStyleProps,
    containerStyleProps,
    removeFromFavourite,
    showLabel = false
}: FavouriteButtonProps) => {
    const { addProductToFavourites, removeProductFromFavourites, isChecked, inProgress } =
        useFavouriteButton({
            checked
        });
    const { loginAllowed, showDisabledLoginPopup } = useApp();
    const intl = useIntl();
    const { asPath, push } = useRouter();
    const session = useSession();

    const [tooltipText, setTooltipText] = useState('');
    const [selectedColor, notSelectedColor] = useToken('colors', ['red.main', 'grey.600']);

    const buttonClickHandler = useCallback(async () => {
        if (!loginAllowed) {
            showDisabledLoginPopup();
            return;
        }
        if (!session.data) {
            setCookie(CookieKeys.addToFavouritesProductCode, productCode);
            await push(RoutePath.SignIn.concat('?redirect=', asPath));
            return;
        }
        if (inProgress) {
            return;
        }
        if (!isChecked) {
            await addProductToFavourites(productCode);
            return;
        }
        await removeProductFromFavourites(productCode);
        removeFromFavourite?.(productCode);
    }, [
        loginAllowed,
        session.data,
        inProgress,
        isChecked,
        removeProductFromFavourites,
        productCode,
        removeFromFavourite,
        push,
        asPath,
        addProductToFavourites,
        showDisabledLoginPopup
    ]);

    useEffect(() => {
        const translationKey = isChecked
            ? 'favorites.remove-from-favourites.tooltip-text'
            : 'favorites.add-to-favourites.tooltip-text';
        setTooltipText(intl.formatMessage({ id: translationKey }));
    }, [isChecked, intl]);

    return (
        <Flex {...containerStyleProps}>
            <Button
                isLoading={inProgress}
                pos="absolute"
                right={4}
                top={4}
                variant="link"
                display="flex"
                fontSize={18}
                _hover={{
                    color: selectedColor
                }}
                _focus={{
                    color: selectedColor
                }}
                zIndex={5}
                onClick={buttonClickHandler}
                innerContainerProps={{
                    display: 'flex',
                    alignItems: 'center'
                }}
                {...buttonStyleProps}
                color={isChecked ? selectedColor : buttonStyleProps?.color || notSelectedColor}
            >
                <Tooltip hasArrow label={!inProgress && tooltipText}>
                    <HeartIcon
                        color={isChecked ? selectedColor : 'inherit'}
                        fill={isChecked ? selectedColor : 'transparent'}
                    />
                </Tooltip>
                {showLabel && <Text ml={1}>{isChecked ? labelChecked : labelUnchecked}</Text>}
            </Button>
        </Flex>
    );
};
