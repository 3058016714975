import { Box, Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useSession } from 'next-auth/react';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AddToCart from '@/components/AddToCart';
import { BadgeNew } from '@/components/Badges/BadgeNew';
import CurrentPrice from '@/components/Card/CurrentPrice';
import { FavouriteButton } from '@/components/FavouriteButton/FavouriteButton';
import { H3 } from '@/components/Heading';
import ProductImage from '@/components/Image/ProductImage';
import { ProductPrice, ProductUnitPrice } from '@/components/Product';
import { ProductCode } from '@/components/Product/ProductCode/ProductCode';
import { ProductLowestPrice } from '@/components/Product/ProductLowestPrice/ProductLowestPrice';
import ProductQuantityHandler from '@/components/ShoppingCart/ProductQuantityHandler';
import { UnavailableProductButton } from '@/components/UI/Buttons/UnavailableProductButton';
import NavLink from '@/components/UI/Links/NavLink';

import { Product } from '@/models/api';
import { ErrorCode } from '@/models/api/Error';
import { DisabledReason, ProductCardProps } from '@/models/props/ProductCardProps';
import { ProductUtil } from '@/utils';

export const ProductCard = (props: ProductCardProps) => {
    const {
        addToCart = true,
        categories,
        code,
        currency,
        discount,
        grossPrice,
        grossPriceBeforeDiscount,
        lowestPriceIn30Days,
        isNew,
        mainImage,
        maximalProductCountInOrder,
        netPrice,
        netPromoPrice,
        netUnitPrice,
        notAvailableMessage,
        points,
        productAvailableForSale,
        slug,
        title,
        unit,
        imageWrapperProps,
        detailsProps,
        imageProps,
        linkToProductPage = true,
        subscribed = false,
        favourite,
        showFavourite = true
    } = props;
    const session = useSession();
    const { currentBasket, cartQuantity, fetchingData, basketLocked } = useShoppingCart();
    const [basketProduct, setBasketProduct] = useState<Product>();
    const [currQuantity, setCurrQuantity] = useState<number>();
    const [inCart, setInCart] = useState<boolean>(false);
    const productURL = ProductUtil.getProductUrl({
        code,
        slug,
        categories
    });
    const { controls } = currentBasket || {};
    const funcPullQuantity = (q: number) => {
        setCurrQuantity(q);
    };
    const obj = useMemo(
        () => ({
            currentBasket,
            cartQuantity
        }),
        [currentBasket, cartQuantity]
    );

    useEffect(() => {
        const inBasket = obj.currentBasket?.products.find((i) => i.code === code);

        if (inBasket) {
            setCurrQuantity(inBasket.quantity);
            setInCart(true);
            setBasketProduct(inBasket);

            return;
        }

        setInCart(false);
    }, [code, currQuantity, obj]);

    return (
        <Flex rowGap={6} as="article" w="100%" h="100%" flexDirection="column" overflow="hidden">
            <Box pos="relative" w="100%">
                <Flex justifyContent="center" alignItems="center" {...imageWrapperProps}>
                    {linkToProductPage && grossPrice ? (
                        <NavLink href={productURL} position="relative" w="100%">
                            <ProductImage
                                mainImage={mainImage}
                                title={title}
                                width={imageProps?.width || 450}
                                height={imageProps?.height || 300}
                            />
                        </NavLink>
                    ) : (
                        <ProductImage
                            mainImage={mainImage}
                            title={title}
                            width={imageProps?.width || 450}
                            height={imageProps?.height || 300}
                        />
                    )}
                </Flex>
                {isNew && (
                    <>
                        <BadgeNew
                            boxProps={{
                                pos: 'absolute',
                                top: 4,
                                left: 4
                            }}
                        />
                    </>
                )}
                {showFavourite && (
                    <FavouriteButton
                        key={code}
                        checked={favourite}
                        productCode={code}
                        removeFromFavourite={props?.removeFromFavourite}
                    />
                )}
            </Box>
            <Box display="flex" flexDirection="column" height="50%" {...detailsProps}>
                <Flex alignItems="center" justifyContent="space-between" mb={6}>
                    <ProductCode productId={code} />
                    {session.data?.user && points > 0 && (
                        <Text fontSize="sm" color="grey.700">
                            {points} <FormattedMessage id="pkt" />
                        </Text>
                    )}
                </Flex>
                <H3 size="h4" fontWeight="normal" mt={1} minH={10}>
                    {linkToProductPage && grossPrice ? (
                        <NavLink href={productURL}>{title}</NavLink>
                    ) : (
                        <Text>{title}</Text>
                    )}
                </H3>
                <Box position="relative" minH={75}>
                    <Box
                        opacity={basketProduct && inCart ? 0 : 1}
                        pointerEvents={basketProduct && inCart ? 'none' : 'auto'}
                    >
                        <ProductPrice
                            grossPrice={grossPrice}
                            grossPriceBeforeDiscount={grossPriceBeforeDiscount}
                            currency={currency}
                            productAvailableForSale={productAvailableForSale}
                            discount={discount}
                            code={code}
                            new={isNew}
                            notAvailableMessage={notAvailableMessage}
                        />
                        <ProductUnitPrice
                            grossPrice={grossPrice}
                            netPrice={netPrice}
                            netPromoPrice={netPromoPrice}
                            netUnitPrice={netUnitPrice}
                            unit={unit}
                            currency={currency}
                            productAvailableForSale={productAvailableForSale}
                        />
                        <ProductLowestPrice
                            discount={discount}
                            isNew={isNew}
                            lowestPriceIn30Days={lowestPriceIn30Days}
                            currency={currency}
                        />
                    </Box>
                    {basketProduct && currency && inCart && (
                        <Box
                            pointerEvents={fetchingData ? 'none' : 'auto'}
                            opacity={fetchingData ? 0.5 : 1}
                        >
                            <CurrentPrice
                                product={basketProduct}
                                currQuantity={currQuantity ?? 0}
                                currency={currency || ''}
                            />
                        </Box>
                    )}
                </Box>
                {productAvailableForSale !== undefined && addToCart && !basketLocked && (
                    <Box mt="auto">
                        {productAvailableForSale && grossPrice ? (
                            currQuantity !== undefined && inCart ? (
                                <>
                                    {controls?.editable ? (
                                        <Box
                                            pt={5}
                                            width="100%"
                                            pointerEvents={fetchingData ? 'none' : 'auto'}
                                            opacity={fetchingData ? 0.5 : 1}
                                        >
                                            <ProductQuantityHandler
                                                setQuantity={funcPullQuantity}
                                                code={code}
                                                quantity={currQuantity}
                                                maxOrder={maximalProductCountInOrder}
                                                variant="xl"
                                                fetchingData={fetchingData}
                                            />
                                        </Box>
                                    ) : (
                                        controls?.disabledReason ===
                                            DisabledReason.PendingTransferPayment && (
                                            <Box
                                                display="grid"
                                                alignItems="center"
                                                textAlign="center"
                                                w="100%"
                                                h="52px"
                                            >
                                                <Text color="red">
                                                    <FormattedMessage id={ErrorCode.disabled} />
                                                </Text>
                                            </Box>
                                        )
                                    )}
                                </>
                            ) : (
                                <Flex
                                    justifyContent="end"
                                    pt={5}
                                    width="100%"
                                    pointerEvents={fetchingData ? 'none' : 'auto'}
                                    opacity={fetchingData ? 0.5 : 1}
                                >
                                    <AddToCart
                                        code={code}
                                        quantity={1}
                                        setQuantity={funcPullQuantity}
                                        maxOrder={maximalProductCountInOrder}
                                    />
                                </Flex>
                            )
                        ) : (
                            session.data && (
                                <UnavailableProductButton code={code} subscribed={subscribed} />
                            )
                        )}
                    </Box>
                )}
                {basketLocked && (
                    <Box mt="auto" pt={4}>
                        <Box
                            borderWidth={1}
                            borderColor="grey.400"
                            borderRadius={4}
                            p={4}
                            pointerEvents="none"
                            textColor="grey.400"
                            fontWeight="medium"
                            textAlign="center"
                            textTransform="uppercase"
                            w="100%"
                        >
                            <FormattedMessage id="basked.locked" />
                        </Box>
                    </Box>
                )}
            </Box>
        </Flex>
    );
};
