import { Box, BoxProps } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

type BadgeNewProps = {
    label?: string;
    boxProps?: BoxProps;
};

export const BadgeNew = ({ label, boxProps }: BadgeNewProps) => {
    return (
        <Box
            borderRadius={19}
            color="white"
            py={2}
            px={4}
            bgGradient="linear(90deg, #A87A28, #FDCF5C)"
            fontWeight={600}
            {...boxProps}
        >
            <FormattedMessage id={label || 'new'} />
        </Box>
    );
};
